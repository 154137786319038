<template>
  <div id="fullscreen_game">
    <section class="full-game">
      <div class="fullgame-inner">
        <iframe
          ref="gameFrame"
          scrolling="no"
          id="iframe"
          :src="`https://stage.xgame.glaze.live/${
            platFormType === false ? 'desktop' : 'mobile'
          }/`"
          allow="fullscreen"
        ></iframe>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { MachineNamespace, MachineActions } from "@/store/machine";

export default {
  props: {},
  data() {
    const params = new URL(document.location).searchParams;

    return {
      platFormType: false, // [ false = Desktop, true = Mobile ]
      machineId: params.get("id"),
      playerId: params.get("playerId"),
      playerToken: params.get("externalToken")
    };
  },

  computed: {},

  beforeDestroy() {
    // iframe message 리스너 제거
    window.removeEventListener("message", this.receiveMessage);
  },

  // 브라우저 뒤로가기 버튼 클릭시 호출 이벤트
  beforeRouteLeave(to, from, next) {
    next(false);
    return "";
  },

  mounted() {
    // iframe message 리스너 추가
    window.addEventListener("message", this.receiveMessage);

    // 플랫폼 확인 (데스크톱, 모바일)
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.platFormType = true; // 모바일 플랫폼
    }
  },

  methods: {
    ...mapActions(MachineNamespace, {
      // ACTION_CREATE_MACHINE_PLAYER: MachineActions.ACTION_CREATE_MACHINE_PLAYER,
      ACTION_DELETE_MACHINE_PLAYER: MachineActions.ACTION_DELETE_MACHINE_PLAYER
    }),

    // 게임 클라이언트로부터 전달받은 준비완료 메시지
    receiveMessage(e) {
      this.player_ban = false;

      switch (e.data.functionName) {
        case "ReceiveGameState":
          e.source.postMessage(
            {
              functionName: "ReceiveGameState",
              data: {
                token: this.playerToken,
                machine_id: this.machineId,
                currency: "PHP"
              }
            },
            "*"
          );
          break;
        case "MoveToMain":
          this.closeGame();
          break;
        case "BanToMain":
          this.closeGame();
          break;
        default:
          break;
      }
    },

    closeGame() {
      const machine_id = this.machineId;
      const player_id = this.playerId;
      const external_token = this.playerToken;

      // 선택한 머신에 사용자 정보 삭제
      this.ACTION_DELETE_MACHINE_PLAYER({
        machine_id,
        player_id,
        external_token
      }).then(res => {
        if (res.status == 200 || res.status === 404) {
          top.window.close();
        }
      });
    },

    // iframe postMessage 전달
    sendPostMessage() {
      this.$refs.gameFrame.contentWindow.postMessage(
        { functionName: "OpenCreditOutPopup" },
        "*"
      );
    }
  }
};
</script>
