<template>
  <div class="xs-container">
    <section class="xs-section security-section">
      <h1>Security</h1>
      <!-- <div class="tab-section">
        <v-tabs v-model="tab" background-color="transparent">
          <v-tab
            v-for="item in securityItems"
            :key="item"
            :disabled="item === 'Verify' || item === 'Self Exclusion'"
          >
            <span>{{ item }}</span>
          </v-tab>
        </v-tabs>
      </div> -->
    </section>
    <!--
    <section class="security_tab-items">
      <v-tabs-items v-model="tab">
        <v-tab-item :key="securityItems.Security">
          <div class="security-sections">
            <section class="border-box">
              <div class="method-box">
                <h2 class="method-title">Password</h2>
                <v-form ref="password_form" class="method-form">
                  <div class="form-group">
                    <label for="oldPassword">Old Password *</label>
                    <v-text-field
                      id="oldPassword"
                      :rules="oldPasswordRules"
                      required
                      v-model="oldPassword"
                      placeholder="Old Password"
                      autocomplete="off"
                    ></v-text-field>
                  </div>
                  <div class="form-group">
                    <label for="new_password">New Password *</label>
                    <v-text-field
                      id="new_password"
                      v-model="newPassword"
                      :rules="newPasswordRules"
                      required
                      placeholder="New Password"
                      autocomplete="off"
                    ></v-text-field>
                  </div>
                  <div class="form-group">
                    <label for="confirm_password">Confirm New Password *</label>
                    <v-text-field
                      id="confirm_password"
                      v-model="confirmPassword"
                      :rules="confirmPasswordRules"
                      required
                      placeholder="Confirm New Password"
                      autocomplete="off"
                    ></v-text-field>
                  </div>
                </v-form>
              </div>

              <div class="method-btn">
                <v-btn>Save</v-btn>
              </div>
            </section>
            <section class="border-box">
              <div class="method-box">
                <h2 class="method-title">Two Factor Authentication</h2>
                <div class="verification-box">
                  <div class="qr-box">
                    <div class="copy-section">
                      <p class="copy-exp">
                        Scan the QR-code using the app or enter the secret key
                        manually
                      </p>
                      <div class="copy-box">
                        <p>LNQWCWH3U5LEXQLD</p>
                        <v-btn></v-btn>
                      </div>
                    </div>
                    <div class="qr-section">
                      <div class="qr-img">
                        <img
                          src="@assets/img/security/qrcode_sample.png"
                          alt=""
                        />
                      </div>
                      <div class="qr-notice">
                        <div class="notice-text">
                          <i></i>
                          <p>
                            Save this code, it will be the only way to regain
                            access to your account if there is no access to the
                            authenticator
                          </p>
                        </div>
                        <div class="agree-message">
                          <div class="check-area">
                            <input
                              type="checkbox"
                              id="agree"
                              v-model="checkTerm"
                            />
                            <label for="agree"></label>
                          </div>

                          <p>I've saved the recovery code</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="verification-code">
                  <div class="code_input-section">
                    <p>Verification Code From App</p>
                    <v-form ref="verify_code_form">
                      <input
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length==1) return false;"
                      />
                      <input
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length==1) return false;"
                      />
                      <input
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length==1) return false;"
                      />
                      <input
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length==1) return false;"
                      />
                      <input
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length==1) return false;"
                      />
                      <input
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length==1) return false;"
                      />
                    </v-form>
                  </div>
                </div>
              </div>

              <div class="method-btn">
                <v-btn>Enable 2FA</v-btn>
              </div>
            </section>
            <section class="border-box">
              <div class="method-box">
                <h2 class="method-title">Email</h2>
                <v-form ref="email_form" class="method-form">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <v-text-field
                      id="email"
                      :rules="emailRules"
                      required
                      v-model="email"
                      placeholder="Email"
                      autocomplete="off"
                    ></v-text-field>
                  </div>
                </v-form>
              </div>

              <div class="method-btn">
                <v-btn>Save</v-btn>
              </div>
            </section>
            <section class="border-box">
              <div class="method-box">
                <h2 class="method-title">Phone Number</h2>
                <v-form ref="mobile_form" class="method-form">
                  <div class="form-group">
                    <label for="mobile">Phone Number</label>
                    <v-text-field
                      id="mobile"
                      :rules="mobileRules"
                      required
                      v-model="mobile"
                      placeholder="phone number"
                      autocomplete="off"
                    ></v-text-field>
                  </div>
                  <div class="form-group">
                    <label for="code">Code</label>
                    <v-text-field
                      v-model="code"
                      id="code"
                      required
                      placeholder="6-Digit Code"
                      autocomplete="off"
                    ></v-text-field>
                    <v-btn class="send">Send</v-btn>
                  </div>
                </v-form>
              </div>

              <div class="method-btn">
                <v-btn>Save</v-btn>
              </div>
            </section>
          </div>
        </v-tab-item>
        <v-tab-item :key="securityItems.Verify"> </v-tab-item>
        <v-tab-item :key="securityItems.SelfExclusion"> </v-tab-item>
      </v-tabs-items>
    </section>
    -->
    <section class="xs-section">
      <div class="security-sections">
        <section class="border-box">
          <div class="method-box">
            <h2 class="method-title">Password</h2>
            <v-form ref="password_form" class="method-form">
              <div class="form-group">
                <v-text-field
                  id="oldPassword"
                  :rules="oldPasswordRules"
                  required
                  v-model="oldPassword"
                  placeholder="Old Password"
                  autocomplete="off"
                ></v-text-field>
              </div>
              <div class="form-group">
                <v-text-field
                  id="new_password"
                  v-model="newPassword"
                  :rules="newPasswordRules"
                  required
                  placeholder="New Password"
                  autocomplete="off"
                ></v-text-field>
              </div>
              <div class="form-group">
                <v-text-field
                  id="confirm_password"
                  v-model="confirmPassword"
                  :rules="confirmPasswordRules"
                  required
                  placeholder="Confirm New Password"
                  autocomplete="off"
                ></v-text-field>
              </div>
            </v-form>
          </div>

          <div class="method-btn">
            <v-btn>Save</v-btn>
          </div>
        </section>
        <!-- <section class="border-box">
          <div class="method-box">
            <h2 class="method-title">Two Factor Authentication</h2>
            <div class="verification-box">
              <div class="qr-box">
                <div class="copy-section">
                  <p class="copy-exp">
                    Scan the QR-code using the app or enter the secret key
                    manually
                  </p>
                  <div class="copy-box">
                    <p>LNQWCWH3U5LEXQLD</p>
                    <v-btn></v-btn>
                  </div>
                </div>
                <div class="qr-section">
                  <div class="qr-img">
                    <img src="@assets/img/security/qrcode_sample.png" alt="" />
                  </div>
                  <div class="qr-notice">
                    <div class="notice-text">
                      <i></i>
                      <p>
                        Save this code, it will be the only way to regain access
                        to your account if there is no access to the
                        authenticator
                      </p>
                    </div>
                    <div class="agree-message">
                      <div class="check-area">
                        <input type="checkbox" id="agree" v-model="checkTerm" />
                        <label for="agree"></label>
                      </div>

                      <p>I've saved the recovery code</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="verification-code">
              <div class="code_input-section">
                <p>Verification Code From App</p>
                <v-form ref="verify_code_form">
                  <input
                    type="number"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length==1) return false;"
                  />
                  <input
                    type="number"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length==1) return false;"
                  />
                  <input
                    type="number"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length==1) return false;"
                  />
                  <input
                    type="number"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length==1) return false;"
                  />
                  <input
                    type="number"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length==1) return false;"
                  />
                  <input
                    type="number"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length==1) return false;"
                  />
                </v-form>
              </div>
            </div>
          </div>

          <div class="method-btn">
            <v-btn>Enable 2FA</v-btn>
          </div>
        </section>
        <section class="border-box">
          <div class="method-box">
            <h2 class="method-title">Email</h2>
            <v-form ref="email_form" class="method-form">
              <div class="form-group">
                <label for="email">Email</label>
                <v-text-field
                  id="email"
                  :rules="emailRules"
                  required
                  v-model="email"
                  placeholder="Email"
                  autocomplete="off"
                ></v-text-field>
              </div>
            </v-form>
          </div>

          <div class="method-btn">
            <v-btn>Save</v-btn>
          </div>
        </section>
        <section class="border-box">
          <div class="method-box">
            <h2 class="method-title">Phone Number</h2>
            <v-form ref="mobile_form" class="method-form">
              <div class="form-group">
                <label for="mobile">Phone Number</label>
                <v-text-field
                  id="mobile"
                  :rules="mobileRules"
                  required
                  v-model="mobile"
                  placeholder="phone number"
                  autocomplete="off"
                ></v-text-field>
              </div>
              <div class="form-group">
                <label for="code">Code</label>
                <v-text-field
                  v-model="code"
                  id="code"
                  required
                  placeholder="6-Digit Code"
                  autocomplete="off"
                ></v-text-field>
                <v-btn class="send">Send</v-btn>
              </div>
            </v-form>
          </div>

          <div class="method-btn">
            <v-btn>Save</v-btn>
          </div>
        </section> -->
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tab: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      email: null,
      mobile: null,
      securityItems: ["Security", "Verify", "Self Exclusion"],
      dummyPassword: "asdf1234!",
      isCorrect: false,
      isnewCorrect: false,
      isCheckCorrect: false,
      oldPasswordRules: [
        v => !!v || "Please enter a password",
        v => this.checkPassword(v)
      ],
      newPasswordRules: [
        v => !!v || "Password is required",
        v =>
          /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/.test(
            v
          ) ||
          "Please enter your password including letters, numbers, and special characters."
      ],
      confirmPasswordRules: [
        v => !!v || "Password is required",
        v => v === this.newPassword || "Passwords do not match"
      ],
      emailRules: [
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      mobileRules: [
        v =>
          !v ||
          /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/.test(v) ||
          "Phone Number must be valid"
      ],
      checkTerm: false,
      keyAllowed: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      code: null
    };
  },
  methods: {
    checkPassword(value) {
      if (value == this.dummyPassword) {
        this.isCorrect = !this.isCorrect;
      } else {
        return "Please enter the correct password";
      }
    },
    isNumber(e) {
      const keyPress = e.key;
      console.log(keyPress);
    }
  }
};
</script>
