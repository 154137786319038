<!-- 
alert modal 사용 시 참고 사항
* v-dialog content-class="alert" 추가하기(필수)
 -->
<template>
  <div class="alert-common modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{ message.title }}</h5>
      <button
        type="button"
        class="btn-close"
        @click="closeDialog(false)"
      ></button>
    </div>
    <div class="message-area modal-body">
      <p class="alert-message">
        <span v-html="subTitleParsing"></span>
      </p>
    </div>
    <div class="message-button" v-if="isConfirm">
      <v-btn @click="closeDialog(false)">{{ $t("button.cancel") }}</v-btn>
      <v-btn class="accent" @click="returnResult">{{ message.btnText }}</v-btn>
    </div>
    <div class="message-button" v-else>
      <v-btn class="accent" @click="closeDialog(false)">{{
        message.btnText
      }}</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    /**
     * props 정보
     * <message>
     * message title = alert title
     * message text = alert text(content)
     * massage btnText = alert button text
     * --------
     * <isConfirm>
     * true 일 때 confirm 용 버튼 출력 (버튼 2개)
     * false 일 때 alert용 버튼 출력 ( 버튼 1개 )
     */
    message: {
      type: Object,
      default: () => ({
        title: "Are You Sure to Exit?",
        text: "Just a few seconds and you can start your real slot machine experience",
        btnText: "Return"
      })
    },
    isConfirm: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    subTitleParsing() {
      let result = this.message.text.replaceAll("\n", "<br />");
      return result;
    }
  },

  methods: {
    // 모달 닫기 이벤트 emit
    closeDialog(changed) {
      this.$emit("closeDialog", changed);
    },
    // confirm alert 창 시 confirm 후의 액션 method
    returnResult() {
      this.$emit("returnResult");
    }
  }
};
</script>
