<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      xDown: null,
      yDown: null
    };
  },
  mounted() {
    // passive =  스크롤 성능 향상을 위한 옵션, preventDefault을 이용하여 이벤트를 막기 위해 사용
    //touch가 시작 됐을 때
    document.addEventListener("touchstart", this.handleTouchStart, {
      passive: false
    });
    //touch가 된 상태로 움직일 때 발생
    document.addEventListener("touchmove", this.handleTouchMove, {
      passive: false
    });
  },
  methods: {
    handleTouchStart(e) {
      // touch가 시작됐을 때 clientX,Y값
      this.xDown = e.touches[0].clientX;
      this.yDown = e.touches[0].clientY;
    },

    handleTouchMove(e) {
      if (!this.xDown || !this.yDown) {
        return;
      }

      // touch가 된 상태로 움직임이 발생했을 때 clientX,Y값
      let xUp = e.touches[0].clientX;
      let yUp = e.touches[0].clientY;

      // 좌우 이동값
      let xDiff = this.xDown - xUp;
      let yDiff = this.yDown - yUp;

      // 좌우 이동값이 상하 이동값 보다 큰 경우 (절대값 비교하기) 좌우 swipe event 막기
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        e.preventDefault();
      }
      /* x,y값 초기화 */
      this.xDown = null;
      this.yDown = null;
    }
  }
};
</script>
