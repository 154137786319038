<template>
  <div class="alert-common modal-content">
    <div class="modal-header">
      <!-- TODO: 아이콘 추가  -->
      <h5 class="modal-title">
        <v-icon class="notify-icon"></v-icon>
      </h5>
    </div>
    <div class="message-area modal-body">
      <p class="alert-message">
        {{ $t("duplicateMessage.duplicateMessage") }} {{ `${time_second}s` }}
      </p>
    </div>
    <div class="message-button">
      <v-btn class="accent" @click="closeDialog(false)">{{
        $t("duplicateMessage.duplicateMessageBtn")
      }}</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timer: null,
      time_second: 10
    };
  },

  mounted() {
    this.timer = setInterval(async () => {
      this.time_second--;

      if (this.time_second == 0) {
        await this.closeDialog();
      }
    }, 1000);
  },

  methods: {
    // 모달 닫기 이벤트 emit
    async closeDialog() {
      clearInterval(this.timer);

      this.$emit("closeDialog");
    }
  }
};
</script>
