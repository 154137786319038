<template>
  <section class="slot-allgame xs-section">
    <div class="allgame-inner">
      <div class="allgame-box">
        <div class="title-box">
          <div class="allgame-title">
            <h3>All Games</h3>
          </div>
          <div class="game-etc">
            <v-btn
              class="favorite-filter"
              elevation="0"
              :disabled="disableFavorite"
              :class="this.isFavorite ? 'active' : ''"
              @click="handleFavorite()"
            >
              <v-icon></v-icon>
              {{ $t("allGames.favorites") }}</v-btn
            >
            <v-btn class="quick-join" elevation="0">
              <v-icon></v-icon>
              Quick Join</v-btn
            >
          </div>
        </div>
        <div class="main-games">
          <ul id="hot_games" class="main-game-lists">
            <li
              class="main-game-list hot"
              v-for="(item, index) in filterList"
              :key="index"
            >
              <div class="main-game-box" :class="item.state === 2 ? 'use' : ''">
                <div class="main-game-img hot" @click="handleGame(item)">
                  <div class="click-img">
                    <div class="game-img">
                      <img
                        :src="require(`@assets/img/main/${item.url}`)"
                        alt="hotgame1"
                      />
                    </div>
                    <div class="game-img" v-if="item.url02 !== null">
                      <img
                        :src="require(`@assets/img/main/${item.url02}`)"
                        alt="hotgame1"
                      />
                    </div>
                  </div>
                  <div class="game-info">
                    <div class="game-state">
                      <p
                        class="state"
                        :class="[
                          {
                            disabled: item.state == 0,
                            idle: item.state == 2,
                            disuse:
                              item.state == 1 ||
                              item.state === 3 ||
                              item.state === 4
                          }
                        ]"
                      >
                        {{ machineState(item.state) }}
                      </p>
                    </div>
                    <div class="machine-num">
                      <p class="machine-id">#{{ item.machine_number }}</p>
                    </div>
                  </div>

                  <div class="state-filter">
                    <div class="use-state watch" v-if="item.state == 2">
                      <div class="machine-logo">
                        <img
                          :src="require(`@assets/img/main/${item.machine_url}`)"
                        />
                      </div>
                      <v-btn class="watch-btn play">
                        <v-icon></v-icon>
                        <span>{{ $t("machineStatus.playNow") }}</span>
                      </v-btn>
                    </div>
                    <div class="use-state watch" v-else-if="item.state == 3">
                      <div class="machine-logo">
                        <img
                          :src="require(`@assets/img/main/${item.machine_url}`)"
                        />
                      </div>
                      <v-btn class="watch-btn">
                        <v-icon></v-icon>
                        <span>{{ $t("machineStatus.watching") }}</span>
                      </v-btn>
                    </div>
                    <div class="use-state" v-else-if="item.state == 4">
                      <img src="@assets/img/main/locked-icon.png" alt="" />
                      <p class="state">{{ $t("machineStatus.locked") }}</p>
                    </div>
                    <div class="use-state" v-else>
                      <img src="@assets/img/state-icon.png" alt="" />
                      <p class="state">{{ $t("machineStatus.maintenance") }}</p>
                    </div>
                  </div>
                </div>
                <div class="main-game-title hot">
                  <p>{{ item.title }}</p>
                  <div class="like-icon" @click="handleLike(index)">
                    <i
                      id="heart"
                      class="heart-icon"
                      :class="item.isLike ? 'active' : ''"
                    ></i>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <v-dialog
        id="signin_modal"
        v-if="signInDialog"
        v-model="signInDialog"
        activator="parent"
        width="auto"
      >
        <SignInDialog @closeDialog="closeSignInDialog" />
      </v-dialog>

      <v-dialog
        content-class="alert"
        v-if="notifyDialog"
        v-model="notifyDialog"
        activator="parent"
        width="auto"
      >
        <AlertDialog
          @closeDialog="closeNotifyDialog"
          :message="notifyMessage"
          :isConfirm="false"
        />
      </v-dialog>

      <PlayViewDialog
        :dialog.sync="playViewDialog"
        :machine="viewTargetMachine"
        @close="closePlayViewDialog"
        @handleGame="handleViewTargetMachineGame"
      ></PlayViewDialog>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { AuthNamespace, AuthActions } from "@/store/auth";
import {
  MachineNamespace,
  MachineActions,
  MachineMutations
} from "@/store/machine";

import SignInDialog from "@views/components/common/dialog/SignInDialog.vue";
import AlertDialog from "@views/components/common/dialog/AlertDialog.vue";
import PlayViewDialog from "@views/components/common/dialog/PlayViewDialog.vue";

import EventBus from "@/utils/EventBus";

import { HubConnectionState } from "@microsoft/signalr";

const MACHINE_STATE = {
  DISABLED: 0,
  PREPARING: 1,
  IDLE: 2,
  USING: 3,
  Locked: 4
};

export default {
  components: { SignInDialog, AlertDialog, PlayViewDialog },
  props: {
    selectedGameThemeId: {
      type: String,
      required: true
    },
    allowedGameIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      slotGames: [
        {
          title: "Luxury 50 Lions",
          url: "slotgame_img00.png"
        },
        {
          title: "Double Money Link",
          url: "slotgame_img01.png"
        },
        {
          title: "5 Dragons Ultra",
          url: "slotgame_img02.png"
        },
        {
          title: "Coin Combo",
          url: "slotgame_img03.png"
        },
        {
          title: "Fu Dai Lian Lain",
          url: "slotgame_img04.png"
        }
      ],
      signInDialog: false,
      notifyDialog: false,
      playViewDialog: false,
      notifyMessage: null,
      viewTargetMachine: {},
      isFavorite: false // 필터 박스 즐겨찾기 토글 상태
    };
  },
  mounted() {
    // 퀵 조인 버튼 이벤트 리스터
    EventBus.$on("quickJoinGame", this.quickJoinGame);

    if (this.getMachineConnectionState) {
      this.notifyMessage = {
        title: this.$t("notifyMessage.title"),
        text: this.$t("notifyMessage.text"),
        btnText: this.$t("button.confirm")
      };
      this.notifyDialog = true;
    }

    // 머신 리스트 조회 요청
    this.ACTION_READ_MACHINE_LIST({ bFilter: true }).then(() => {
      if (
        this.getMachineList.length > 0 &&
        this.$machineHub.hub.state == HubConnectionState.Connected
      ) {
        // 머신 id 중복 제거
        const machine_id_list = new Set(
          this.getMachineList.map(m => {
            return m.machine_id;
          })
        );
        const filter_list = [...machine_id_list];

        // 머신 id 상태 요청
        filter_list.forEach(id => {
          if (id != null) {
            this.$machineHub.hub.invoke("UsingStatus", id);
          }
        });
      }
    });
  },

  computed: {
    ...mapGetters(AuthNamespace, [
      "getExternalToken",
      "getPlayerId",
      "getLoginCheck"
    ]),

    ...mapGetters(MachineNamespace, [
      "getMachineList",
      "getMachineId",
      "getMachineConnectionState"
    ]),

    machineState() {
      return state => {
        let result = "";
        switch (state) {
          case MACHINE_STATE.IDLE:
            // 사용가능
            result = this.$t("machineStatus.idle");
            break;
          case MACHINE_STATE.USING:
            // 사용중
            result = this.$t("machineStatus.occupied");
            break;
          case MACHINE_STATE.DISABLED:
            // 사용불가
            result = this.$t("machineStatus.maintenance");
            break;
          case MACHINE_STATE.PREPARING:
            // 준비중
            result = this.$t("machineStatus.preparing");
            break;
          case MACHINE_STATE.Locked:
            // 잠김
            result = this.$t("machineStatus.locked");
            break;
          default:
            break;
        }
        return result;
      };
    },

    // 필터링된 게임 머신 리스트 반환 이벤트
    filterList() {
      let machineFilterList = this.getMachineList;

      // selectedTheme가 0일 때 allowedGameIds로 필터링, 아니면 selectedGameThemeId로 필터링
      if (this.selectedGameThemeId === "all" && this.allowedGameIds.length) {
        machineFilterList = machineFilterList.filter(game =>
          this.allowedGameIds.includes(game.gameId)
        );
      } else if (this.selectedGameThemeId !== 0) {
        machineFilterList = machineFilterList.filter(
          game => game.gameId === this.selectedGameThemeId
        );
      }

      // 즐겨찾기 필터링 적용
      if (this.isFavorite) {
        machineFilterList = machineFilterList.filter(game => game.isLike);
      }

      return machineFilterList;
    },

    // 즐겨찾기 버튼 비활성화 검사
    disableFavorite() {
      const favoriteMachine = this.filterList.filter(m => m.isLike === true);
      if (favoriteMachine.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapMutations(MachineNamespace, {
      MUTATION_MACHINE_KICK_OFF: MachineMutations.MUTATION_MACHINE_KICK_OFF
    }),

    ...mapActions(AuthNamespace, {
      ACTION_AUTH_LOGIN: AuthActions.ACTION_AUTH_LOGIN,
      ACTION_AUTH_LOGOUT: AuthActions.ACTION_AUTH_LOGOUT
    }),

    ...mapActions(MachineNamespace, {
      ACTION_READ_MACHINE_LIST: MachineActions.ACTION_READ_MACHINE_LIST,
      ACTION_CREATE_MACHINE_PLAYER: MachineActions.ACTION_CREATE_MACHINE_PLAYER,
      ACTION_DELETE_MACHINE_PLAYER: MachineActions.ACTION_DELETE_MACHINE_PLAYER,
      ACTION_READ_MACHINE_GAME_TYPE:
        MachineActions.ACTION_READ_MACHINE_GAME_TYPE
    }),

    handleLike(index) {
      this.getMachineList[index].isLike = !this.getMachineList[index].isLike;
    },
    handleShow(index) {
      this.getMachineList[index].isShow = !this.getMachineList[index].isShow;
    },

    // 게임 머신 선택 이벤트
    async handleGame(item) {
      // 로그인 상태
      if (this.getLoginCheck) {
        if (
          this.viewTargetMachine != item &&
          item.state === MACHINE_STATE.USING
        ) {
          this.viewTargetMachine = item;
          this.ACTION_READ_MACHINE_GAME_TYPE(item.machine_id);
          this.openPlayViewDialog();
        } else if (item.state === MACHINE_STATE.IDLE) {
          const machine_id = item.machine_id;

          // 게임 페이지내에서 이동
          if (window.location.pathname == "/game") {
            // 선택한 머신과 현재 사용중인 머신 id가 같은 경우
            if (this.getMachineId === machine_id) {
              return;
            }
            // Emit 메시지 발송
            this.$emit("moveGameEvent", machine_id);
          }
          // 메인페이지에서 이동
          else {
            // 선택한 머신에 사용자 정보 등록
            this.createMachinePlayer(machine_id);
          }
        }
      } else {
        this.openSignInDialog();
      }
    },
    async handleViewTargetMachineGame() {
      await this.handleGame(this.viewTargetMachine);
    },
    handleFavorite() {
      this.isFavorite = !this.isFavorite;
    },
    openSignInDialog() {
      this.signInDialog = true;
    },
    openPlayViewDialog() {
      this.playViewDialog = true;
    },
    closeSignInDialog() {
      this.signInDialog = false;
    },
    closeNotifyDialog() {
      this.notifyDialog = false;

      if (window.location.pathname == "/game") this.$router.replace("");
      if (this.getMachineConnectionState) {
        this.MUTATION_MACHINE_KICK_OFF(false);
      }
    },
    closePlayViewDialog() {
      this.playViewDialog = false;
      this.viewTargetMachine = {};
    },

    // 퀵 조인 버튼 이벤트
    quickJoinGame() {
      this.handleGame(this.getMachineList[0]);
    },

    // 머신 플레이어 생성
    async createMachinePlayer(machine_id) {
      console.log("createMachinePlayer");
      const player_id = this.getPlayerId;
      const external_token = this.getExternalToken;

      this.ACTION_CREATE_MACHINE_PLAYER({
        machine_id,
        player_id,
        external_token
      }).then(res => {
        switch (res.status) {
          case 200:
            {
              if (window.location.pathname == "/game") {
                this.$router.replace({
                  path: "game",
                  query: { id: machine_id }
                });
              } else {
                this.$router.push({
                  path: "game",
                  query: { id: machine_id }
                });
              }
            }
            break;
          case 401:
            if (this.getPlayerId !== undefined) {
              this.ACTION_AUTH_LOGOUT();
              this.openSignInDialog();
            }
            break;

          case 406:
            /**
             * 406 : The Player is using another machine.
             */
            this.notifyMessage = {
              title: this.$t("notifyMessage.title"),
              text: this.$t("machine.error406"),
              btnText: this.$t("button.confirm")
            };
            this.notifyDialog = true;
            break;
          case 409:
            console.log(res.data.detail);
            /**
             * 409 : There are no seats left.
             */
            this.notifyMessage = {
              title: this.$t("notifyMessage.title"),
              text: this.$t("machine.error409"),
              btnText: this.$t("button.confirm")
            };
            this.notifyDialog = true;
            break;

          default:
            break;
        }
      });
    }
  }
};
</script>
