import { HubConnectionBuilder } from "@microsoft/signalr";
import router from "@router";

import { AuthNamespace } from "@/store/auth";
import { MachineNamespace, MachineMutations } from "@/store/machine";

const baseURL = process.env.VUE_APP_API_URL;

export class PlayerHub {
  constructor(store) {
    this.hub = null;
    this.store = store;
  }

  init() {
    const external_token = this.store.getters["auth/getExternalToken"];

    if (external_token) {
      this.hub = new HubConnectionBuilder()
        .withUrl(`${baseURL}/ws/PlayerInfoHub?token=${external_token}`)
        .build();

      this.hub.onclose(() => {
        this.startHubConnection();
      });

      // 크레딧 상태 업데이트 수신
      this.hub.on("PlayerCredit", rCredit => {
        const loginState = this.store.getters["auth/getLoginCheck"];

        if (loginState) {
          // 현재 로그인한 사용자와 크레딧 업데이트된 사용자가 동일한 경우
          this.store.commit(
            `${AuthNamespace}/MUTATION_UPDATE_CREDIT`,
            {
              credit: rCredit
            },
            {
              root: true
            }
          );
        }
      });

      // 현재 연결된 머신 정보가 있는 경우 게임 페이지 이동
      this.hub.on("CurrentMachine", machineId => {
        // 사용자 선택 머신 업데이트
        this.store.commit(
          `${MachineNamespace}/${MachineMutations.MUTATION_USER_MACHINE}`,
          machineId
        );

        if (router.currentRoute.name !== "game") {
          router.replace({
            path: "game",
            query: { id: machineId }
          });
        }
      });

      this.hub.on("CurrentUserOverlap", token => {
        // 현재 사용자 토큰 정보와 다른 경우 로그아웃 처리
        const userTokenInfo = this.store.getters["auth/getExternalToken"];
        if (userTokenInfo !== token) {
          // 중복 로그인 true
          this.store.commit(`${AuthNamespace}/MUTATION_DUPLICATE_USER`);
        }
      });

      this.startHubConnection();
    }
  }

  startHubConnection() {
    try {
      this.hub.start();
    } catch (err) {
      setTimeout(this.startHubConnection, 5000);
    }
  }
}
