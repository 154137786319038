<template>
  <nav id="xs_navi" class="side-menu">
    <div class="menu-inner">
      <div class="link-area">
        <ul class="link-lists">
          <li class="link-list">
            <a href="#">
              <v-icon class="vip"></v-icon>
              <span>VIP</span>
            </a>
          </li>
          <li class="link-list">
            <a href="#">
              <v-icon class="event"></v-icon>
              <span>EVENT</span>
            </a>
          </li>
          <li class="link-list">
            <a href="#">
              <v-icon class="cs"></v-icon>
              <span>CS</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {};
</script>
