import Vue from "vue";
import App from "./App.vue";

import router from "./router"; // router
import store from "./store"; //vuex
import vuetify from "./plugins/vuetify"; //vuetify
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VueCookies from "vue-cookies";

import { i18n } from "@/locales/i18n"; // i18n

import { MachineHub } from "./hub/machineHub";
import { PlayerHub } from "./hub/playerHub";

// 페이지별 css 연결
import "./assets/scss/style.scss";

if (process.env.NODE_ENV === "local") {
  Vue.config.devtools = true;
} else {
  Vue.config.devtools = false;
}

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify
});
Vue.use(VueCookies);

/// 머신 허브
const machineHub = new MachineHub(store);
machineHub.init();
Vue.prototype.$machineHub = machineHub;
///

/// 플레이어 허브
const playerHub = new PlayerHub(store);
playerHub.init();
Vue.prototype.$playerHub = playerHub;
///

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
