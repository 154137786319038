<template>
  <div class="vertical-modal-inner">
    <button
      type="button"
      class="btn-close"
      @click="closeDialog(false)"
    ></button>
    <div class="vertical-modal game-history">
      <div class="vertical-inner">
        <div class="vertical_title-box">
          <div class="title-box">
            <h2>
              Game <br />
              History
            </h2>
          </div>
          <div class="vertical-tab tab3">
            <v-tabs v-model="tab" background-color="transparent" vertical>
              <v-tab
                v-for="(item, index) in gameHistoryItem"
                :key="index"
                :disabled="item === 'Jackpot'"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
          </div>
        </div>
        <div class="vertical-tab-items">
          <v-tabs-items v-model="tab">
            <v-tab-item :key="gameHistoryItem.XGames">
              <div class="tab-section d-flex">
                <v-tabs v-model="tabs" background-color="transparent">
                  <v-tab
                    v-for="(item, index) in periodTabs"
                    :key="index"
                    :disabled="
                      item.title === '3days' ||
                      item.title === '7days' ||
                      item.title === '30days'
                    "
                  >
                    {{ item.title }}
                  </v-tab>
                </v-tabs>
              </div>

              <div class="tabs-items">
                <v-tabs-items v-model="tabs">
                  <v-tab-item :key="periodTabs.Today">
                    <section class="tab_item-section">
                      <div class="border-box">
                        <div class="history-result">
                          <p class="result-period">2 games in 30 days</p>
                          <p class="result-amount">- $ 23.05</p>
                        </div>
                      </div>
                    </section>
                    <section
                      class="tab_item-section"
                      v-for="(item, index) in gameHistoryList"
                      :key="index"
                    >
                      <v-expansion-panels class="border-box">
                        <v-expansion-panel class="expansion-panel history">
                          <v-expansion-panel-header>
                            <div class="accord-section">
                              <p class="accord-result">{{ item.gameTitle }}</p>
                              <p class="accord_result-status fail">
                                {{ item.gameResult }}
                              </p>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div class="result-box d-flex">
                              <div class="result-img">
                                <img
                                  :src="
                                    require(`@assets/img/dialog/game-history/${item.gameImg}`)
                                  "
                                  alt=""
                                />
                              </div>
                              <div class="result-info">
                                <ul class="info-lists">
                                  <li class="info-list">
                                    <p class="result-title">Game Rounds:</p>
                                    <p class="result-text">
                                      {{ item.gameRound }}
                                    </p>
                                  </li>
                                  <li class="info-list">
                                    <p class="result-title">Bet Count:</p>
                                    <p class="result-text">
                                      {{ item.betCount }}
                                    </p>
                                  </li>
                                  <li class="info-list">
                                    <p class="result-title">Free Games:</p>
                                    <p class="result-text">
                                      {{ item.freeGames }}
                                    </p>
                                  </li>
                                </ul>
                                <div class="result-btn">
                                  <v-btn>Play</v-btn>
                                  <v-btn class="detail">Details</v-btn>
                                </div>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </section>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-tab-item>
            <v-tab-item :key="gameHistoryItem.AllGames">
              <div class="tab-section d-flex">
                <v-tabs v-model="allGameTabs" background-color="transparent">
                  <v-tab
                    v-for="(item, index) in allGamePeriodTabs"
                    :key="index"
                    :disabled="
                      item.title === '3days' ||
                      item.title === '7days' ||
                      item.title === 'Today'
                    "
                  >
                    {{ item.title }}
                  </v-tab>
                </v-tabs>
              </div>

              <div class="tabs-items">
                <v-tabs-items v-model="tabs">
                  <v-tab-item :key="allGamePeriodTabs.day30">
                    <section class="tab_item-section">
                      <div class="border-box">
                        <div class="history-result">
                          <p class="result-period">Total 30days:</p>
                          <p class="result-amount">
                            <span>Profit:</span>- $ 23.05
                          </p>
                        </div>
                      </div>
                    </section>
                    <section
                      class="tab_item-section"
                      v-for="(item, index) in allGameHistoryList"
                      :key="index"
                    >
                      <v-expansion-panels class="border-box">
                        <v-expansion-panel class="expansion-panel history">
                          <v-expansion-panel-header>
                            <div class="accord-header">
                              <div class="accord-section">
                                <p class="accord-result">
                                  {{ item.gameTitle }}
                                </p>
                                <p class="accord_result-status fail">
                                  {{ item.gameResult }}
                                </p>
                              </div>
                              <div class="accord-copy d-flex">
                                <div class="copy-box">
                                  <p class="copy-text result-text">
                                    {{ item.copyText }}
                                  </p>
                                  <v-btn class="copy-btn"></v-btn>
                                </div>
                                <p class="accord-time">{{ item.gameTime }}</p>
                              </div>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-divider></v-divider>
                            <div class="result-box d-flex">
                              <div class="result-info w-100">
                                <ul class="info-lists">
                                  <li class="info-list">
                                    <p class="result-title">Game Name:</p>
                                    <p class="result-text">
                                      {{ item.gameTitle }}
                                    </p>
                                  </li>
                                  <li class="info-list">
                                    <p class="result-title">Game Type:</p>
                                    <p class="result-text">
                                      {{ item.gameType }}
                                    </p>
                                  </li>
                                  <li class="info-list">
                                    <p class="result-title">Provider:</p>
                                    <p class="result-text">
                                      {{ item.provider }}
                                    </p>
                                  </li>
                                  <li class="info-list">
                                    <p class="result-title">Bet Amount:</p>
                                    <p class="result-text">
                                      {{ item.betAmount }}
                                    </p>
                                  </li>

                                  <li class="info-list">
                                    <p class="result-title">Payout Amount:</p>
                                    <p class="result-text">
                                      {{ item.payoutAmount }}
                                    </p>
                                  </li>
                                  <li class="info-list">
                                    <p class="result-title">Bill Status:</p>
                                    <p class="result-text">
                                      {{ item.billStatus }}
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </section>
                    <div class="text-center">
                      <v-pagination v-model="page" :length="2"></v-pagination>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-tab-item>
            <v-tab-item :key="gameHistoryItem.Jackpot"> </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tab: null,
      tabs: null,
      allGameTab: null,
      allGameTabs: null,
      gameHistoryItem: ["XGames", "AllGames", "Jackpot"],
      periodTabs: [
        {
          title: "Today",
          value: "today"
        },
        {
          title: "3days",
          value: "day3"
        },
        {
          title: "7days",
          value: "day7"
        },
        {
          title: "30days",
          value: "day30"
        }
      ],
      allGamePeriodTabs: [
        {
          title: "Today",
          value: "today"
        },
        {
          title: "3days",
          value: "day3"
        },
        {
          title: "7days",
          value: "day7"
        },
        {
          title: "30days",
          value: "day30"
        }
      ],
      gameHistoryList: [
        {
          gameTitle: "5 Treasures - 0006",
          gameResult: "-$ 6.12",
          gameImg: "game_history.png",
          gameRound: 4,
          betCount: 7,
          freeGames: "--"
        },
        {
          gameTitle: "5 Treasures - 0006",
          gameResult: "-$ 6.12",
          gameImg: "game_history.png",
          gameRound: 4,
          betCount: 7,
          freeGames: "--"
        },
        {
          gameTitle: "5 Treasures - 0006",
          gameResult: "-$ 6.12",
          gameImg: "game_history.png",
          gameRound: 4,
          betCount: 7,
          freeGames: "--"
        }
      ],
      allGameHistoryList: [
        {
          gameTitle: "5 Treasures - 0006",
          gameResult: "-$ 6.12",
          copyText: "20231124170703807055002",
          gameTime: "11/24 08:07",
          gameType: "real",
          provider: "X Games",
          betAmount: "$ 0.16",
          payoutAmount: "$0.00",
          billStatus: "Settled"
        },
        {
          gameTitle: "5 Treasures - 0006",
          gameResult: "-$ 6.12",
          copyText: "20231124170703807055002",
          gameTime: "11/24 08:07",
          gameType: "real",
          provider: "X Games",
          betAmount: "$ 0.16",
          payoutAmount: "$0.00",
          billStatus: "Settled"
        },
        {
          gameTitle: "5 Treasures - 0006",
          gameResult: "-$ 6.12",
          copyText: "20231124170703807055002",
          gameTime: "11/24 08:07",
          gameType: "real",
          provider: "X Games",
          betAmount: "$ 0.16",
          payoutAmount: "$0.00",
          billStatus: "Settled"
        }
      ],
      page: 1
    };
  },
  methods: {
    // 모달 닫기 이벤트 emit
    closeDialog(changed) {
      this.$emit("closeDialog", changed);
    }
  }
};
</script>
