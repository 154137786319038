import store from "../store";

export default class PlayerWebSocket {
  constructor() {
    this.socket = null;
  }

  connect() {
    const token = store.getters["auth/getExternalToken"];

    this.socket = new WebSocket(
      `${process.env.VUE_APP_API_URL.replace("http://", "ws://").replace(
        "https://",
        "wss://"
      )}/ws/player?token=${token}`
    );

    this.socket.addEventListener("open", () => {
      console.log("PlayerWebSocket is Open");
    });

    // 웹 소켓 연결이 끊어진 경우
    this.socket.addEventListener("close", () => {
      console.log("PlayerWebSocket is Close");

      // 현재 연결된 머신이 있는 경우 재연결 시도
      const machineId = store.getters["machine/getMachineId"];
      if (machineId != undefined) {
        this.connect();
      }
    });

    // API 서버로부터 전달받은 메시지 수신
    this.socket.addEventListener("message", event => {
      try {
        let parseData = JSON.parse(event.data);

        if ("type" in parseData && parseData.type == "timeout") {
          // AFK 유형 업데이트
          store.commit(`auth/MUTATION_UPDATE_AFK_INFO`, parseData, {
            root: true
          });
        }
      } catch (error) {
        false;
      }
    });
  }

  sendMessage() {
    let message = {
      id: "message-0011",
      type: "echo",
      payload: [1, "2"]
    };
    this.socket.send(message);
  }

  disconnect() {
    this.socket.close();
  }
}
