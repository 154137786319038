import { machineDeviceApi } from "@/api";

const MachineDeviceNamespace = "machineDevice";

const MachineDeviceMutations = {
  MUTATION_UPDATE_MACHINE_DEVICE_LIST: "MUTATION_UPDATE_MACHINE_DEVICE_LIST", // 머신 장치 목록 업데이트
};

const MachineDeviceActions = {
  ACTION_READ_MACHINE_DEVICE_LIST: "ACTION_READ_MACHINE_DEVICE_LIST", // 머신 리스트 조회 요청
};

const createInitialState = () => ({
  machine_device_list: [],
});

const getters = {
  getMachineDeviceList(state) {
    return state.machine_device_list;
  },
};

const mutations = {
  // 머신 리스트 업데이트
  [MachineDeviceMutations.MUTATION_UPDATE_MACHINE_DEVICE_LIST](state, res) {
    state.machine_device_list = res;
  },
};

const actions = {
  // 머신 리스트 조회 요청
  async [MachineDeviceActions.ACTION_READ_MACHINE_DEVICE_LIST](context, payload) {
    const { commit } = context;
    const { machine_id, external_token } = payload;
    await machineDeviceApi.requestMachineDeviceList(machine_id, external_token).then(res => {
      commit("MUTATION_UPDATE_MACHINE_DEVICE_LIST", res.data);
    });
  },
};

export { MachineDeviceNamespace, MachineDeviceMutations, MachineDeviceActions };

export default {
  state: () => createInitialState(),
  getters,
  mutations,
  actions,
  namespaced: true
};
