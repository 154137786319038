const Axios = require("axios");
// import VueCookies from "vue-cookies";

const baseURL =
  process.env.NODE_ENV === "local"
    ? `http://${window.location.hostname}:3000`
    : process.env.VUE_APP_ROOT_API;

const axios = Axios.create({ baseURL: baseURL, withCredentials: true });

export default axios;
