<template>
  <div class="xs-container terms">
    <section class="xs-section">
      <div class="terms-container">
        <h1>Membership Terms and Conditions</h1>
        <div class="terms-box">
          <h2>Guidelines:</h2>
          <p class="terms-info text-indent">
            These Terms and Conditions shall serve as business agreement between
            Remote Slot and its members/players. By agreeing to the Terms and
            Conditions, the member/player willfully and knowingly accept and
            understand the intent and concequences as stated in this T&C. It is
            imperative that you carefully review these Terms and Conditions to
            comprehend the program's regulations, advantages, and obligations
            within the program. Further, by becoming a member of the Remote Slot
            membership program, you acknowledge and agree to abide by the T&C as
            outlined herein. This T&C may be modified without prior notice at
            the exclusive discretion of Remote Slot. Furthermore, Remote Slot
            retains the right, at its sole discretion, to approve or decline
            this application. ￼
          </p>
        </div>
        <div class="terms-box">
          <h2>Membership Application:</h2>
          <ol class="terms-info list">
            <li class="terms-info__list">
              Enrollment in the program is complimentary, and only one
              membership per individual is permitted.
            </li>
            <li class="terms-info__list">
              The Member recognizes and affirms that they must be at least 21
              years old to join the program and must provide a valid
              government-issued ID. The applicant consents to the capture of
              their photograph during registration for identity verification,
              aiming to prevent potential misuse of the user ID.
            </li>
            <li class="terms-info__list">
              Any legal, governmental entity, private or public groups,
              associations, or individuals currently under PAGCOR's Exclusion
              Order are ineligible for membership application. Membership for
              individuals previously subjected to a PAGCOR Exclusion Order but
              had it revoked will be determined at the management's discretion.
            </li>
            <li class="terms-info__list">
              The program does not apply to individuals who have voluntarily
              enrolled in any gaming regulatory self-exclusion initiative
            </li>
          </ol>
        </div>
        <div class="terms-box">
          <h2>Gaming:</h2>
          <ol class="terms-info list">
            <li class="terms-info__list">
              Players are required to input their Remote Slot account
              information and password.
            </li>
            <li class="terms-info__list">
              It is the player’s sole responsibility to ensure there are funds
              in their account, which the player can cash in through an agent.
            </li>
            <li class="terms-info__list">
              Players shall receive advance notice one hour before the system
              undergoes maintenance, and it is expected that the gaming facility
              will be inaccessible during this period.
            </li>
            <li class="terms-info__list">
              Players are required to log out for daily & monthly settlement and
              may be logged out of their respective accounts before the daily
              preventive maintenance for the machines takes place, scheduled
              from 05:00 a.m. to 06:00 a.m. Philippine Time. The timing may vary
              based on the machines' status.
            </li>
            <li class="terms-info__list">
              In instances of machine lock up due to machine error including but
              not limited to cancel credit, machine hang / freeze, and
              progressive jackpot winnings, the standard operational procedures
              will apply. Issue resolution may involve temporarily disabling
              machine functionality to address these issues.
            </li>
            <li class="terms-info__list">
              If there is no activity in a free game for 15 minutes, the
              management will automatically assist in activating the machine ‘s
              free spin function.
            </li>
            <li class="terms-info__list">
              If a player is disconnected from the Remote Slot after placing a
              bet, the game will proceed independently, and any resulting
              payouts shall follow the game's outcome. This policy applies to
              any game session during the time of disconnection. All results
              shall be reflected to the player’s Remote Slot online account
              balance such as winnings and deductions.
            </li>
            <li class="terms-info__list">
              If there are any concerns or inquiries regarding the Remote Slot,
              the Player can reach out to the helpdesk for assistance.
            </li>
            <li class="terms-info__list">
              All matters arising from or relating to the use of Remote Slot and
              the terms and conditions herein shall follow and governed by the
              laws of the Philippines
            </li>
            <li class="terms-info__list">
              Decisions rendered by Remote Slot concerning any dispute related
              to the usage of Remote Slot online account are final and binding.
            </li>
          </ol>
        </div>
        <div class="terms-box">
          <p class="terms-info ml-0">
            By selecting the <strong>"I agree"</strong> button below, you
            acknowledge that you have read, comprehended, and consented to the
            preceding terms and conditions that govern the utilization of Remote
            Slot.<br /><br />
            Remote Slot acknowledges and respects your rights to data privacy.
          </p>
        </div>
      </div>
    </section>
    <div class="back-btn">
      <router-link to="/"><v-icon>mdi-arrow-left</v-icon>Back</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
