<template>
  <div class="division-inner modal-content">
    <div class="division-header">
      <h5 class="modal-title">Filter</h5>
      <button
        type="button"
        class="btn-close"
        @click="closeDialog(false)"
      ></button>
    </div>
    <v-divider></v-divider>
    <div class="filter-modal">
      <section class="filter-section">
        <h2>Status</h2>
        <v-btn-toggle v-model="toggleStatus" mandatory>
          <v-btn>
            <span>All</span>
          </v-btn>
          <v-btn> <span>Processing</span></v-btn>
          <v-btn><span>Success</span></v-btn>
          <v-btn><span>Fail</span></v-btn>
        </v-btn-toggle>
      </section>
      <section class="filter-section">
        <h2>Currency</h2>
        <v-btn-toggle v-model="toggleCurrency" mandatory>
          <v-btn>All</v-btn>
          <v-btn>Fiat</v-btn>
          <v-btn>Crypto</v-btn>
        </v-btn-toggle>
      </section>
    </div>
    <div class="division-footer">
      <div class="footer-btn">
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn class="active" @click="confirmClickBtn">Confirm</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      status: ["All", "Processing", "Success", "Fail"],
      toggleStatus: undefined,
      toggleCurrency: undefined
    };
  },
  methods: {
    closeDialog(changed) {
      this.$emit("closeDialog", changed);
    },
    confirmClickBtn() {
      alert("confirm");
    }
  }
};
</script>
