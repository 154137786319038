<template>
  <v-container>
    <v-card>
      <v-card-title> Web Socket 테스트 페이지 </v-card-title>
    </v-card>

    <v-card class="mt-5 ma-auto" flat>
      <v-btn class="mr-5" @click="connect"> 소켓 연결 </v-btn>
      <v-btn class="mr-5" @click="disconnect"> 소켓 연결해제 </v-btn>
      <v-btn class="mr-5" @click="sendMessage"> 소켓 데이터 전송 </v-btn>
    </v-card>

    <v-card class="mt-5 ma-auto d-flex" flat>
      <v-text-field v-model="authToken" label="Token"></v-text-field>
    </v-card>
  </v-container>
</template>
<script>
import { CustomWebSocket } from "@root/ws/webSocket";

export default {
  data() {
    return {
      authToken: "",
      socket: null,
      count: 0,
      web_socket: null
    };
  },

  created() {},

  mounted() {
    // this.authToken =
    //   "eyJhbGciOiJub25lIiwidHlwIjoiSldUIn0.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoibWVtYmVyMSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IlBsYXllciIsIlNlc3Npb24iOiJmODBhYWFhMi00MjkwLTQ3ZWEtYTNkOC1lMzBiODQyYjcyMGMiLCJBZ2VudCI6ImFnZW50MSIsImV4cCI6MTcwMzgzMzUwMH0.";
  },

  methods: {
    // async connect() {
    //   console.log("connect");

    //   this.socket = await new WebSocket(
    //     `ws://localhost:5198/ws/service/player/${this.authToken}`
    //   );

    //   this.socket.onmessage = function (event) {
    //     console.log(event.data);
    //   };
    // },

    // sendMessage() {
    //   const message = {
    //     id: "message-0011",
    //     type: "echo",
    //     payload: [1, "2"]
    //   };
    //   this.socket.send(JSON.stringify(message));
    // },
    // disconnect() {
    //   this.socket.close();
    // }

    async connect() {
      this.web_socket = new CustomWebSocket();
      this.web_socket.token = this.authToken;

      if (this.authToken.trim().length === 0) {
        alert("토큰 정보를 입력해주세요.");
        return;
      }

      this.web_socket.connect();
    },

    sendMessage() {
      const message = {
        id: "message-0011",
        type: "echo",
        payload: [1, "2"]
      };
      this.web_socket.sendMessage(message);
    },
    disconnect() {
      this.web_socket.disconnect();
    }
  }
};
</script>
