import axios from "./axios";

// const host_info = "http://localhost:5198";
// const host_info = "https://stage.xapi.glaze.live";

const host_info = process.env.VUE_APP_API_URL;

/**
 * [ XSAPI ] 머신 - Hot Games 리스트 조회
 * @returns
 */
export const requestMachineList = async () => {
  let response = null;

  try {
    response = await axios.get("/machine/list");
  } catch (error) {
    throw new Error(error);
  }
  return response;
};

/**
 * [ XSAPI ] 머신 - All Games 리스트 조회
 * @returns
 */
export const requestMachineListAll = async gameId => {
  let response = null;

  const data = {
    gameId: gameId
  };

  try {
    response = await axios.post("/machine/listAll", data);
  } catch (error) {
    throw new Error(error);
  }
  return response;
};

/**
 * 머신 게임 스크린 타입 조회
 * @returns
 */
export const requestMachineGameType = async machine_id => {
  let response = null;
  try {
    response = await axios.get(`/machine/${machine_id}/gametype`);
  } catch (err) {
    return err.response;
  }
  return response;
};

/**
 * [ XAPI ] 머신 - 플레이어 매핑 요청 API
 * @param {*} machine_id
 * @param {*} player_id
 * @param {*} external_token
 * @returns
 */
export const requestMachinePlayer = async (
  machine_id,
  player_id,
  external_token
) => {
  let response = null;
  try {
    response = await axios.post(
      `${host_info}/machines/${machine_id}/players/${player_id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${external_token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true"
        }
      }
    );
  } catch (error) {
    return error.response;
  }

  return response;
};

/**
 * [ XAPI ] 머신 - 플레이어 매핑 삭제 요청 API
 * @param {*} machine_id
 * @param {*} player_id
 * @param {*} external_token
 * @returns
 */
export const requestMachinePlayer_delete = async (
  machine_id,
  player_id,
  external_token
) => {
  let response = null;
  try {
    response = await axios.delete(
      `${host_info}/machines/${machine_id}/players/${player_id}`,
      {
        headers: {
          Authorization: `Bearer ${external_token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true"
        }
      }
    );
  } catch (error) {
    return error.response;
  }

  return response;
};
