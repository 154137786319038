import axios from "./axios";

/**
 * [XSAPI] Banner - banner list 조회
 * @returns
 */
export const requestBannerList = async () => {
  let response = null;

  try {
    response = await axios.get("/banners/list");
  } catch (error) {
    throw new Error(error);
  }
  return response;
};
