import { machineApi } from "@/api";

const MachineNamespace = "machine";

const MachineMutations = {
  MUTATION_UPDATE_MACHINE_LIST: "MUTATION_UPDATE_MACHINE_LIST", // 사용자 리스트 업데이트
  MUTATION_UPDATE_MACHINE_STATE: "MUTATION_UPDATE_MACHINE_STATE", // 머신 상태 업데이트
  MUTATION_USER_MACHINE: "MUTATION_USER_MACHINE", // 사용자 선택 머신 ID 정보 업데이트
  MUTATION_MACHINE_KICK_OFF: "MUTATION_MACHINE_KICK_OFF", // 사용자 선택 머신 연결 해제 알림 유무
  MUTATION_UPDATE_MACHINE_GAME_TYPE: "MUTATION_UPDATE_MACHINE_GAME_TYPE" // 머신 게임 스크린 타입 업데이트
};

const MachineActions = {
  ACTION_READ_MACHINE_LIST: "ACTION_READ_MACHINE_LIST", // 머신 리스트 조회 요청
  ACTION_CREATE_MACHINE_PLAYER: "ACTION_CREATE_MACHINE_PLAYER", // 머신-플레이어 생성
  ACTION_DELETE_MACHINE_PLAYER: "ACTION_DELETE_MACHINE_PLAYER", // 머신-플레이어 제거
  ACTION_READ_MACHINE_GAME_TYPE: "ACTION_READ_MACHINE_GAME_TYPE" // 머신 게임 스크린 타입 조회 요청
};

const createInitialState = () => ({
  machine_id: undefined,
  machine_kick_off: false,
  machine_list: [],
  slot_list: [],
  machine_game_type: undefined
});

const getters = {
  getMachineList(state) {
    return state.machine_list;
  },
  getMachineId(state) {
    return state.machine_id;
  },
  getMachineConnectionState(state) {
    return state.machine_kick_off;
  },
  getMachineGameType(state) {
    return state.machine_game_type;
  }
};

const mutations = {
  // 머신 리스트 업데이트
  [MachineMutations.MUTATION_UPDATE_MACHINE_LIST](state, res) {
    state.machine_list = res.list;
  },

  /**
   * 머신 상태 업데이트
   * @param {*} state
   * @param {*} res HUB를 통해 전달 받은 { machineId: 머신 아이디, isUsing: 머신 상태}
   */
  [MachineMutations.MUTATION_UPDATE_MACHINE_STATE](state, res) {
    state.machine_list.map(m => {
      if (m.machine_id == res.machineId) {
        m.state = res.status;
      }
    });
  },

  // 사용자가 현재 사용중인 머신 ID 정보 업데이트
  [MachineMutations.MUTATION_USER_MACHINE](state, res) {
    state.machine_id = res;
  },

  // 사용자가 현재 사용중인 머신 게임 타입 업데이트
  [MachineMutations.MUTATION_UPDATE_MACHINE_GAME_TYPE](state, res) {
    state.machine_game_type = res;
  },

  // 머신 연결 상태 업데이트 (iframe 게임 서버 연결 해제시 업데이트)
  [MachineMutations.MUTATION_MACHINE_KICK_OFF](state, res) {
    state.machine_kick_off = res;
  }
};

const actions = {
  // 머신 리스트 조회 요청
  async [MachineActions.ACTION_READ_MACHINE_LIST](context, payload) {
    const { commit } = context;

    const { bFilter, gameId } = payload;

    if (bFilter) {
      await machineApi.requestMachineList(gameId).then(res => {
        commit("MUTATION_UPDATE_MACHINE_LIST", res.data);
      });
    } else {
      await machineApi.requestMachineListAll(gameId).then(res => {
        commit("MUTATION_UPDATE_MACHINE_LIST", res.data);
      });
    }
  },

  //머신 게임 스크린 타입 조회 요청
  async [MachineActions.ACTION_READ_MACHINE_GAME_TYPE](context, payload) {
    const { commit } = context;
    const machineId = payload;
    let result = await machineApi
      .requestMachineGameType(machineId)
      .then(res => {
        commit("MUTATION_UPDATE_MACHINE_GAME_TYPE", res.data);
        return res.data;
      });
    return result;
  },

  // 머신-플레이어 생성
  async [MachineActions.ACTION_CREATE_MACHINE_PLAYER](context, payload) {
    const { commit } = context;
    const { machine_id, player_id, external_token } = payload;

    let result = await machineApi
      .requestMachinePlayer(machine_id, player_id, external_token)
      .then(res => {
        commit("MUTATION_USER_MACHINE", machine_id);

        return res;
      });
    return result;
  },

  // 머신-플레이어 제거
  async [MachineActions.ACTION_DELETE_MACHINE_PLAYER](context, payload) {
    const { commit } = context;
    const { machine_id, player_id, external_token } = payload;

    commit("MUTATION_USER_MACHINE", undefined);

    let result = await machineApi.requestMachinePlayer_delete(
      machine_id,
      player_id,
      external_token
    );

    return result;
  }
};

export { MachineNamespace, MachineMutations, MachineActions };

export default {
  state: () => createInitialState(),
  getters,
  mutations,
  actions,
  namespaced: true
};
